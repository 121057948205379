  import React, {Component} from 'react';
  import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
  import CustomCamera from '../Camera/CustomCamera';

  class PhotoModal extends Component {
    
    constructor(props) {
        super(props);        
    }

    setReference = cameraInput => {
        this.cameraInput = cameraInput
    }

    triggerScreenshot = _ => {
        this.cameraInput.capture();
    }

    resetCamera = _ => {
        this.cameraInput.retake();
    }

    getPhotoTaken = (photoSrc) => {
        this.props.localHandleMarking(photoSrc);
    }

    render(){
        return(
            <Modal isOpen={this.props.isOpen} className="PhotoModal">
                <ModalBody>
                    <CustomCamera ref={this.setReference} width={300} height={300} getPhotoTaken={this.getPhotoTaken}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="link" className="text-center w-100 text-secondary" onClick={this.triggerScreenshot}>
                        <i className="fas fa-camera fa-4x"></i>
                    </Button>
                </ModalFooter>
            </Modal>
        )      
    }
  }

  export default PhotoModal;