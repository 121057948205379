import React, {Component} from 'react';

class Clock extends Component {	
	constructor(props) {		
		super(props)		
		this.state = {
			time: new Date()
		}		
	}
	
	componentDidMount() {		
		setInterval(this.update, 1000)		
	}
	
	update = () => {		
		this.setState({
			time: new Date()
		})		
	};
	
	render() {		
		const h = this.state.time.getHours()
		const m = this.state.time.getMinutes()
		const s = this.state.time.getSeconds()
		
		return (		
			<span className={this.props.className}>{h % 12}:{(m < 10 ? '0' + m : m)}{h < 12 ? ' am' : ' pm'}</span>		
		)		
	}	
}

export default Clock;