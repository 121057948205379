import React, {useState, useEffect} from 'react';
import SweetAlert from 'sweetalert2-react';

const CustomAlert = props => {
    const [shouldShow, changeShow] = useState(props.show);

    useEffect(_ => {
        changeShow(props.show);

        if(props.show && props.timer) {
            setTimeout(() => {
                if(props.onConfirm && typeof props.onConfirm === "function") {
                    props.onConfirm()
                }

                changeShow(false);
            }, props.timer);
        }        
    }, [props.show]);

    return(
        <SweetAlert
            {...props}
            show={shouldShow}
            title={props.title}
            text={props.text}
            type={props.type}
            onConfirm={props.onConfirm}                        
        />
    )
}

export default CustomAlert;