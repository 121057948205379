import React, {Fragment, Component} from 'react';
import Clock from './clock';
import default_user from './default_user.png';
import moment from 'moment';

const CLOCKIN = 30570;

class SelectPosition extends Component{
    constructor(props){
        super(props);
        this.state = {
            currentDate: moment(new Date()).format('MMMM Do YYYY'),
        }
    }

    renderPositionButtons = _ => {
        return this.props.matchEmployee.positions.map(p => {
            return <button className="btn btn-default Kiosk-positionButton" onClick={_ => this.props.handleMarking(CLOCKIN, p.id)}>{p.name}&nbsp;-&nbsp;<i class="far fa-clock"></i></button>
        });
    }

    goBack = _ => {
        this.props.logOut(true);
    }

    render() {
        return(
            <Fragment>
                <div className={`Kiosk-marking ${this.props.show ? 'visible' : ''}`}>
                    <div className="container-fluid Kiosk-container">
                        <div className="row pt-0 pb-0 mr-0 ml-0">
                            <div className="col-12 col-md-6 order-2 order-md-1">
                                <div className="gen-col-centered" style={{height: "100%", paddingBottom: "30px", paddingTop: "30px"}}>
                                    <div className="Kiosk-dateTime">
                                        <span className="Kiosk-date">{this.state.currentDate}</span>
                                        <Clock className="Kiosk-time" />
                                    </div>
                                    <button className="btn Kiosk-logout" type="button" onClick={this.props.toggleConfirmLogoutModal}>
                                        Log Out&nbsp;&nbsp;
                                        <i class="fas fa-sign-out-alt"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 pl-0 pr-0 mr-0 ml-0 order-1 order-md-2 Kiosk-gradient" style={{height: "100vh"}}>
                                <div className="Kiosk-marking-right" style={{position: "relative"}}>
                                    <button className="btn btn-link" style={{position: "absolute", top: "0", left: "0"}} onClick={this.goBack} type="button">
                                        <i className="fas fa-chevron-left fa-3x text-white"></i>
                                    </button>                                    
                                    <div className="Kiosk-marking-wrapper" style={{marginBottom: "60px"}}>
                                        <img src={this.props.pic || default_user} alt="User Picture" className="Kiosk-profilePic"/>
                                        <span className="Kiosk-profileName text-center mb-2">Hi, {this.props.matchEmployee.name}</span>
                                        <p className="Kiosk-text text-center">Please clock-in to your current position</p>                            
                                    </div>
                                    <div className="Kiosk-marking-wrapper">
                                        {this.renderPositionButtons()}                                                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default SelectPosition;